/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Circle, MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import CustomMarker from "./customMarker";
import MarkerClusterGroup from "react-leaflet-cluster";
import { filterParametersString } from "../Utils";

const MapView = (props) => {
  const {
    isOnHomePage,
    mapMarkersList = [],
    searchFilters,
    selectedProperty = null,
    onShowDetail = () => onShowDetail,
  } = props;

  const [map, setMap] = useState(null);

  const mapboxUrl =
    "https://api.mapbox.com/styles/v1/neutrinodesign/clt7jhcgg00lh01qp37tc7m8u/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibmV1dHJpbm9kZXNpZ24iLCJhIjoiY2x0N2o2OWJmMDBtejJpb2d1ejlweTJhYiJ9.mPTu-PwOPS-QTaztzCI7YQ";
  const attribution =
    'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';

  useEffect(() => {
    if (selectedProperty) {
      let [latitude, longitude] = selectedProperty.position;
      longitude = longitude + 0.02;
      map.setView([latitude, longitude], 14);
    }
  }, [selectedProperty]);

  return (
    <>
      <MapContainer
        className="z-10"
        center={[25.7617, -80.1918]}
        zoom={13}
        scrollWheelZoom={true}
        ref={setMap}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer url={mapboxUrl} attribution={attribution} />
        {selectedProperty && (
          <Circle
            center={selectedProperty.position}
            color="#639bbf"
            fillColor="#639bbf"
            radius={2500}
          />
        )}
        <MarkerClusterGroup>
          {mapMarkersList?.map(
            (prop) =>
              // some data has not position coordinates which crashes the map, hence the check
              prop.position[0] &&
              prop.position[1] && (
                <CustomMarker
                  isOnHomePage={isOnHomePage}
                  key={prop.id}
                  id={prop.id}
                  position={prop.position}
                  price={prop.price}
                  propertyType={prop.type}
                  bedrooms={prop.bedrooms}
                  bathrooms={prop.bathrooms}
                  sqft={prop.squareFeet}
                  imageUrl={prop.imageUrl}
                  onShowDetail={() => onShowDetail(prop.id)}
                  address={prop.address}
                  description={prop.description}
                  filterParametersString={filterParametersString(searchFilters)}
                  highlighted={selectedProperty?.id === prop.id}
                />
              )
          )}
        </MarkerClusterGroup>
      </MapContainer>
    </>
  );
};

export default MapView;
