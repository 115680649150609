/* eslint-disable react-hooks/exhaustive-deps */
// useListings.js
import { useEffect, useState } from "react";
import usePropertyList from "../hooks/usePropertyList";
import { getListings } from "../Api";

const useListings = (searchFilters, queryParams, activePage) => {
  const { isLoading, setIsLoading } = usePropertyList();
  const [mapMarkersList, setMapMarkersList] = useState([]);
  const [totalResults, setTotalResults] = useState(0);

  useEffect(() => {
    const fetchMarkers = async () => {
      setIsLoading(true);
      await getListings(
        searchFilters,
        `${queryParams}&limit=50${getOffsetRange()}`
      )
        .then((response) => {
          setMapMarkersList(response.properties);
          setTotalResults(response.total);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchMarkers();
  }, [searchFilters, activePage, queryParams]);

  const getOffsetRange = () => {
    if (activePage === 1) return "";
    else {
      const val = (activePage - 1) * 50;
      return "&offset=" + val;
    }
  };

  return { isLoading, mapMarkersList, totalResults };
};

export default useListings;
