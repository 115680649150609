import React from "react";
import { Filters } from "./Filters";
import { PropertiesList } from "./PropertiesList";
import { PropertiesPagination } from "./PropertiesPagination";

const ListingsPage = ({
  title,
  searchFilters,
  setSearchFilters,
  activePage,
  setActivePage,
  isLoading,
  mapMarkersList,
  totalResults,
}) => {
  if (isLoading) {
    return (
      <div className="center-container">
        <div className="loading-animation"></div>
      </div>
    );
  }

  return (
    <div className="py-2 md:py-5 pb-0 md:pb-0 px-2 md:px-5">
      <div className="p-2 md:p-5 pb-0 md:pb-0 bg-white rounded-lg drop-shadow-sm">
        <div className="text-3xl font-bold pb-2">{title}</div>
        <Filters
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          setActivePage={setActivePage}
        />
        <div className="py-2 text-sm md:text-base">
          {"Search Over "}{" "}
          <span style={{ fontWeight: 600 }}>{totalResults}</span>
          {" Units to "}
          <span>
            {searchFilters.propertyType === "any"
              ? "rent / purchase"
              : searchFilters.propertyType}
          </span>
        </div>
        <div className="overflow-auto h-[calc(100vh-405px)] md:h-[calc(100vh-345px)]">
          <PropertiesList
            mapMarkersList={mapMarkersList}
            activePage={activePage}
            searchFilters={searchFilters}
          />
        </div>
        <PropertiesPagination
          totalResults={totalResults}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </div>
    </div>
  );
};

export default ListingsPage;
